import React from 'react'
import purple_tick from "../assets/pricing/purpleTick.png"
import gold_tick from "../assets/pricing/goldTick.png"
import platinum_tick from '../assets/pricing/platinumTick.png'
import cancel from "../assets/pricing/cancel.png"

const Pricing = () => {
  return (
    <div>
        <div className="container min-h-[90vh] mt-20 pb-20 px-10">
            <h3 className='font-[400] lg:text-[60px] text-[30px] text-center heading-2 8xl:w-2/3 mx-auto leading-[60px] mb-20'>Flexible Plans Tailored to Your Goals</h3>
            
            {/* PRICING CARDS */}
            <div className="grid lg:grid-cols-3 lg:gap-[20px] gap-10 8xl:gap-[100px]">
                <div className="rounded-[30px] silver-shadow">
                    <div className="px-5 text-center py-5">
                        <h4 className='text-[40px] font-[600]'>SILVER</h4>
                    </div>
                    <div className='silver-banner text-white text-center py-3 text-[22px] font-[700]'>
                        <p>
                            Ghc500.00/month
                        </p>
                    </div>
                    <div className='px-12 py-6 flex flex-col gap-3 mt-4'>
                        <div className="flex gap-2">
                            <img src={purple_tick} alt="" /> <span>1 User</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={purple_tick} alt="" /> <span>3 Employees</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={purple_tick} alt="" /> <span>100mb Storage</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={cancel} alt="" /> <span>Free Website</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-10 mb-20">
                        <button className='silver-banner rounded-[30px] text-white py-[14px] px-[20px]'>
                            CHOOSE PLAN
                        </button>
                    </div>
                </div>
                <div className="rounded-[30px] gold-shadow">
                    <div className="px-5 text-center py-5">
                        <h4 className='text-[40px] font-[600]'>GOLD</h4>
                    </div>
                    <div className='gold-banner text-white text-center py-3 text-[22px] font-[700]'>
                        <p>
                            Ghc800.00/month
                        </p>
                    </div>
                    <div className='px-12 py-6 flex flex-col gap-3 mt-4'>
                        <div className="flex gap-2">
                            <img src={gold_tick} alt="" /> <span>4 Users</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={gold_tick} alt="" /> <span>10 Employees</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={gold_tick} alt="" /> <span>500mb Storage</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={gold_tick} alt="" /> <span>Free Website</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-10 mb-20">
                        <button className='gold-banner rounded-[30px] text-white py-[14px] px-[20px]'>
                            CHOOSE PLAN
                        </button>
                    </div>
                </div>
                <div className="rounded-[30px] platinum-shadow">
                    <div className="px-5 text-center py-5">
                        <h4 className='text-[40px] font-[600]'>PLATINUM</h4>
                    </div>
                    <div className='platinum-banner text-white text-center py-3 text-[22px] font-[700]'>
                        <p>
                            Ghc1000.00/month
                        </p>
                    </div>
                    <div className='px-12 py-6 flex flex-col gap-3 mt-4'>
                        <div className="flex gap-2">
                            <img src={platinum_tick} alt="" /> <span>6 Users</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={platinum_tick} alt="" /> <span>Unlimited Employees</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={platinum_tick} alt="" /> <span>1tb Storage</span>
                        </div>
                        <div className="flex gap-2">
                            <img src={platinum_tick} alt="" /> <span>Free Website</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-center mt-10 mb-20">
                        <button className='platinum-banner rounded-[30px] text-white py-[14px] px-[20px]'>
                            CHOOSE PLAN
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Pricing