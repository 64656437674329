import React from 'react'
import empMan from "../assets/hrProcess/empMan.png"
import recruitment from "../assets/hrProcess/recruitment.png"
import payroll from "../assets/hrProcess/payroll.png"
import performance from "../assets/hrProcess/performance.png"

const HrProcess = () => {
    const processes = [
        {
            "icon":empMan,
            "title":"Employee Management Information",
            'text': "The employee information management section stores employee data and documents, ensuring easy access to personal details, contracts, and reviews."
        },
        {
            "icon":recruitment,
            "title":"Recruitment and Onboarding Feature",
            'text': "The recruitment and onboarding section streamlines hiring with an applicant tracking system and automates new hire onboarding workflows."
        },
        {
            "icon":payroll,
            "title":"Payroll and Benefits Administration",
            'text': "The payroll and benefits administration section automates salary processing and manages employee benefits, providing self-service portals for benefits selection."
        },
        {
            "icon":performance,
            "title":"Performance and Talent Management",
            'text': "The performance and talent management section handles performance appraisals, goal tracking, feedback, and employee training and development programs."
        },
    ]
  return (
    <div id='services'>
        <div className="container py-24">
            <div className="grid lg:grid-cols-2 gap-20 items-end">
                <h3 className='heading-2 text-[40px] font-[500] leading-tight text-center'>
                    Streamlines HR process with efficient data, payroll and performance management.
                </h3>
                <div className="grid lg:grid-cols-2 gap-3 justify-between">
                    <p className='text-gray-600 text-center'>
                        We believe that the human essential to start any successful project and that this is where splendid emotion between the re-generated company.
                    </p>
                    <p className='lg:text-right underline'>
                        All Services
                    </p>
                </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 lg:gap-3 gap-6 mt-24">
                {
                    processes?.map((process, index)=>(
                        <div key={index} className='bg-[#D8E7EF] flex flex-col items-center gap-8 lg:px-3 px-9 py-10 rounded-[30px]'>
                            <img src={process?.icon} alt={process?.title} />
                            <h5 className='text-center text-[25px] md:text-[20px] 8xl:text-[25px] leading-tight'>
                                {process?.title}
                            </h5>
                            <p className='text-center text-[13px] leading-tight'>
                                {process?.text}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default HrProcess