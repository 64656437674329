import React from 'react'
import leftEclipse from "../assets/experienceImages/leftEclipse.png"
import rightEclipse from "../assets/experienceImages/rightEclipse.png"
import plus from "../assets/experienceImages/plus.png"

const Experience = () => {
  return (
    <div className='py-20'>
        <div className="container">
            <div className="grid grid-cols-2 lg:gap-10 gap-5">
                <div className='relative'>
                    <img src={leftEclipse} alt="" className='h-[200px] md:h-auto' />
                    <p className='absolute 8xl:top-44 lg:top-36 top-20 8xl:right-32 right-16 font-[400] 8xl:text-[250px] md:text-[200px] text-[60px] heading-2 text-white'>
                        2
                    </p>
                </div>
                <div>
                  <h3 className='heading-2 w-[194px] md:text-[40px] text-[27px] font-[400] leading-tight 8xl:mt-[120px] md:mt-[50px] '>
                    Year Experience
                  </h3>
                  <p className='8xl:mt-[28px] mt-2 lg:w-[400px] 8lx:mb-24 mb-3 text-[14px]'>
                    We have more than 2 years of experience, we have best liased with companies to produce quality human resource results
                  </p>
                  <div className='relative'>
                    <img src={rightEclipse} alt="" />
                    <img src={plus} alt="" className='absolute md:top-24 md:left-24 top-12 left-12 md:h-auto h-[50px] ' />
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Experience