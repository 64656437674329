import React from 'react'
import choosebg from "../assets/chooseHrm/choosebg.png"
import tick from "../assets/chooseHrm/tick.png"

const ChooseHrm = () => {
    const processes = [
        {
            title: "Information",
            text:"Efficient prescription order management is crucial for the smooth pharmacy operations."
        },
        {
            title: "Productivity",
            text:"Efficient prescription order management is crucial for the smooth pharmacy operations."
        },
        {
            title: "Efficiency",
            text:"Efficient prescription order management is crucial for the smooth pharmacy operations."
        },
        {
            title: "Seamless",
            text:"Efficient prescription order management is crucial for the smooth pharmacy operations."
        },
        
    ]
  return (
    <div className='lg:mx-7'>
        <div className="grid lg:grid-cols-2">
            <div className='bg-choose-bg bg-cover lg:h-full h-[300px]'>
                {/* <img src={choosebg} alt="" /> */}
            </div>
            <div className="bg-[#103142] py-14 px-8 lg:rounded-tr-3xl lg:rounded-br-3xl">
                <h3 className="heading-2 text-white 8xl:text-[60px] text-[24px] leading-tight mb-16 text-center">
                    Choose our HRM system for seamless efficiency. Streamline processes.
                </h3>
                <div className="grid md:grid-cols-2 8xl:gap-40 gap-10">
                    {
                        processes?.map((process)=>(
                            <div className="text-white flex flex-col items-center justify-center">
                                <img src={tick} alt="" />
                                <h5 className="heading-2 text-[24px] my-4">{process?.title}</h5>
                                <p className='text-center'>Efficient prescription order management is crucial for the smooth pharmacy operations.</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChooseHrm