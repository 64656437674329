import React from 'react'

const Banner = () => {
  return (
    <div className='rounded-2xl mx-3 bg-banner-bg lg:h-[900px] h-[256.42px] bg-cover flex justify-end items-end px-7 py-7'>
        <div className='border-r-2 border-white pt-5 pb-2 px-3 text-white lg:text-[24px] w-[370px] text-right'>
            Everything you need to grow a successful team
        </div>
    </div>
  )
}

export default Banner