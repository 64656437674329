import React from 'react'
import client1 from "../assets/clientImages/client1.png"
import quote from "../assets/clientImages/quote.png"
import star from "../assets/clientImages/star.png"
import navArrow from "../assets/clientImages/navArrow.png"

const Clients = () => {
  return (
    <div id='clients' className='py-24'>
        <div className="container grid lg:grid-cols-3 lg:gap-5 gap-20">
            <div className="flex flex-col justify-between items-center">
                <h3 className="heading-2 8xl:text-[60px] text-[40px] text-center leading-tight">
                    Our Honourable Clients
                </h3>
                <div className="text-[#103142] lg:flex flex-col items-center gap-2 w-full hidden ">
                    <div className="flex gap-1">
                        <img src={quote} alt="" />
                        <img src={quote} alt="" />
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={star} alt="" />
                        <h5 className="heading-2 leading-tight mt-2 text-[24px]">
                            4.3
                        </h5>
                    </div>
                    <p>
                        (10 Reviews)
                    </p>
                </div>
            </div>
            <div>
                <div className='flex flex-col gap-6 text-[#103142] px-16 lg:px-4 text-center'>
                    <h3 className='heading-2 text-[100px] leading-tight '>
                        30 +
                    </h3>
                    <p>
                        We have worked with top companies globally and achieved a huge positive vibes
                    </p>
                    <button className='bg-[#103142] text-white px-7 py-3 rounded-full w-max mx-auto'>
                        Explore Service
                    </button> 
                </div>
                <div className="border-t border-gray-400 mt-16 lg:mt-5 px-16 lg:px-3 py-5 text-[#103142]">
                    <h5 className='text-[22px] mb-3 heading-2'>Benedict Aboagye</h5>
                    <p className='text-[13px]'>
                        TeapHRM has revolutionized our operations. It's efficient, organized, and allows us to focus on our core goals. We couldn't be happier with the results.
                    </p>
                    <h3 className='text-[13px] font-[500] mt-2'>
                        4D Radiological Centre
                    </h3>
                    <img className='mt-3' src={navArrow} alt="" />
                </div>
            </div>
            <div className='flex items-center justify-center'>
                <img src={client1} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Clients