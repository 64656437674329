import React from 'react'

const FeaturedTools = () => {
  return (
    <div className='py-20'>
        <div className="container">
            <div className="grid lg:grid-cols-2 mb-7">
                <h3 className='lg:text-[60px] text-[40px] text-center text-[#103142] heading-2 leading-tight lg:w-[80%]'>
                    Featured automated tools
                </h3>
                <div className="flex lg:flex-row flex-col gap-3 justify-between">
                    <div className='w-full'>
                        <p className=' text-gray-500 text-center'>
                            Rest assured with our HR management software. Our commitment ensures reliable solutions tailored to your needs.
                        </p>
                    </div>
                    <p className=' w-full lg:text-right text-center text-[#103142] font-[600] underline heading-2'>
                        All Projects
                    </p>
                </div>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-6">
                <div className='bg-attendance-bg bg-no-repeat rounded-3xl h-[430px] p-7 flex items-end'>
                    <div className='text-white'>
                        <h3 className='font-[500] text-[32px] heading-2'>
                            Attendance
                        </h3>
                        <p>Management</p>
                    </div>
                </div>
                <div className='bg-task-bg bg-no-repeat rounded-3xl h-[430px] p-7 flex items-end'>
                    <div className='text-white'>
                        <h3 className='font-[500] text-[32px] heading-2'>
                            Task
                        </h3>
                        <p>Management</p>
                    </div>
                </div>
                <div className='bg-project-bg bg-no-repeat rounded-3xl h-[430px] p-7 flex items-end'>
                    <div className='text-white'>
                        <h3 className='font-[500] text-[32px] heading-2'>
                            Project
                        </h3>
                        <p>Management</p>
                    </div>
                </div>
                <div className='bg-payroll-bg bg-no-repeat rounded-3xl h-[430px] p-7 flex items-end'>
                    <div className='text-white'>
                        <h3 className='font-[500] text-[32px] heading-2'>
                            Payroll
                        </h3>
                        <p>Management</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FeaturedTools