import React from 'react'
import spikeImg from "../assets/heroImages/spike.png"
import curveArrow from "../assets/heroImages/curveArrow.png"
import person1 from "../assets/heroImages/person1.png"
import person2 from "../assets/heroImages/person2.png"
import person3 from "../assets/heroImages/person3.png"
import computerMan from "../assets/heroImages/computerMan.png"
import bookLady from "../assets/heroImages/bookLady.png"

const Hero = () => {
  return (
    <div id='home' className='bg-[#dbe2c81c] min-h-screen py-40'>
        <div className="container">
            <div className='relative z-10'>
                <h1 className='heading lg:text-[100px] md:text-[70px] text-[30px] font-[600] lg:leading-[100px] leading-tight lg:w-[100%] 8xl:w-[60%] text-center mx-auto'>
                    Elevate your HR with our streamlined solution
                </h1>
                <img src={spikeImg} alt="spike" className='absolute lg:-top-[40px] 8xl:-top-[50px] top-0 8xl:right-48 lg:-right-[50px] right-3 lg:h-[100px] h-[30px]' />
                <img src={curveArrow} alt="curve arrow" className='absolute lg:left-[200px] lg:-bottom-[40px] h-[60px]' />
            </div>
            <div className="mt-20 md:grid lg:grid-cols-4 md:grid-cols-2 md:gap-6">
                <div className='flex lg:flex-col flex-col-reverse md:gap-0 lg:gap-3 gap-6'>
                    <div className="p-7 bg-[#D8E7EF] rounded-[20px]  md:order-1 md:w-[234px] w-full">
                        <h5 className='font-[800] text-[40px]'>250</h5>
                        <p className='w-2/3 text-sm'>
                            Our system is used word-wide.
                        </p>


                        <div className="mt-8 relative">
                            
                            <div className='border-1 border-white h-[60px] w-[60px] rounded-full absolute left-8'>
                                <img src={person2} alt="" />
                            </div>
                            <div className='border-1 border-white h-[60px] w-[60px] rounded-full absolute left-14'>
                                <img src={person3} alt="" />
                            </div>
                            <div className='border-2 border-white h-[60px] w-[60px] rounded-full text-white bg-[#0F3141] flex items-center justify-center text-[30px] absolute left-20'>
                                +
                            </div>
                            <div className='border-1 border-white h-[60px] w-[60px] rounded-full '>
                                <img src={person1} alt="" />
                            </div>
                            
                        </div>
                    </div>

                    <div className="py-8 px-4 bg-[#A2D3D9] rounded-[20px] lg:text-[14px]  md:max-w-[350px] mt-5 w-full">
                        <p>
                            Pay your employees quickly to keep them motivated for work. TeapHRM has got you.
                        </p>
                        <div className='px-6 py-3 border border-black text-black w-max rounded-full mt-5' >
                            Get Started
                        </div>
                    </div>
                </div>
                <div className='col-span-2 lg:flex items-center justify-center hidden'>
                    <img src={computerMan} alt="" className='h-[470px]  object-cover' />
                </div>
                <div className='relative mt-10 '>
                    <div className="bg-[#DCE2C7] mb-4 rounded-[20px] p-10 lg:p-5">
                        <p className='text-[20px] lg:text-[15px] leading-tight'>
                            Say a long goodbye to spending thousands on hiring human resource managers. 
                        </p>
                    </div>
                    <div className="bg-[#DCE2C7] lg:h-[294px] h-[240px] rounded-[20px]">
                        
                    </div>
                    <img src={bookLady} alt="" className='absolute bottom-0 lg:bottom-8 left-[20%] h-[300px] lg:h-[250px] object-cover' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Hero