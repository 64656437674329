import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../sections/Hero'
import Experience from '../sections/Experience'
import HrProcess from '../sections/HrProcess'
import HrmSystem from '../sections/HrmSystem'
import UltimateTool from '../sections/UltimateTool'
import Banner from '../sections/Banner'
import Clients from '../sections/Clients'
import ChooseHrm from '../sections/ChooseHrm'
import FeaturedTools from '../sections/FeaturedTools'
import Contact from '../sections/Contact'
import Footer from '../sections/Footer'
import Pricing from '../sections/Pricing'

const Home = () => {
  return (
    <div>
        <Navbar />
        <Hero />
        <Experience />
        <HrProcess />
        <HrmSystem />
        <UltimateTool />
        <Banner />
        <Clients />
        <ChooseHrm />
        <FeaturedTools />
        <Contact />
        <Pricing />
        <Footer />
    </div>
  )
}

export default Home