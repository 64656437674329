import React from 'react'
import ultimateChart from "../assets/ultimateTool/chart.png"
const UltimateTool = () => {
  return (
    <div className='py-32'>
        <div className="container">
            <h1 className='heading-2 text-center lg:text-[40px] text-[30px] font-[400] 8xl:w-[50%] mx-auto'>
                The Ultimate Tool to Hire, Manage and Pay your Employees
            </h1>
            <div className="grid lg:grid-cols-2 items-center gap-16">
                <div className='lg:order-1 order-2'>
                    <p className='8xl:text-[24px] text-[16px] leading-tight text-center'>We started in the places where we spend most of our <b>waking hours - places </b> where we work. But we're not eventually occured.</p>
                    <div className='mt-10'>
                        <h6 className='text-[16px] font-400 mb-3'>Information</h6>
                        <ul className='flex flex-col gap-2 8xl:w-[45%]'>
                            <li>
                                01. Centre for Binox Consultancy Research, 2017
                            </li>
                            <li>
                                02. Dynamic Manipulation, 2016
                            </li>
                            <li>
                                03. Transformative Physical World, 202
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='lg:order2 order-1'>
                    <img src={ultimateChart} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default UltimateTool