import React from 'react'
import facebook from '../assets/socialMedia/facebook.png'
import instagram from "../assets/socialMedia/instagram.png"
import linkedIn from "../assets/socialMedia/linkedIn.png"
import twitter from "../assets/socialMedia/twitter.png"

const Footer = () => {
  return (
    <div className='bg-[#13263C] py-[45px] text-white'>
        <div className="container">
            <div className="grid md:grid-cols-4 gap-5">
                <div>
                    <h3 className='mb-5 font-[500] text-[22px]'>Services</h3>
                    <ul className='flex flex-col gap-2'>
                        <li>
                            Graphics Design
                        </li>
                        <li>
                            Web Development
                        </li>
                        <li>
                            Software Engineering
                        </li>
                        <li>
                            Mobile App Development
                        </li>
                        <li>
                            UI/UX Design
                        </li>
                        <li>
                            IT Consultancy
                        </li>
                    </ul>
                </div>

                <div className='col-span-2'>
                    <h3 className='mb-5 font-[500] text-[22px]'>Products</h3>
                    <ul className='flex flex-col gap-2'>
                        <li>
                        Epasmart School Managment System Software
                        </li>
                        <li>
                        SwiftPOSgh -Point of Sales Management System
                        </li>
                        <li>
                        TeaHRM -Human Resource Management System
                        </li>
                        <li>
                        GhAccount - Accounting Management System
                        </li>
                        <li>
                        Virtualdoc - Telemedicine App
                        </li>
                        <li>
                        GhFetch - Courier Services Application
                        </li>
                    </ul>
                </div>


                <div>
                    <h3 className='mb-5 font-[500] text-[22px]'>Contact</h3>
                    <ul className='flex flex-col gap-2'>
                        <li>
                            info@epahubb.com
                        </li>
                        <li>
                        +233 531 178 671
                        </li>
                        <li>
                        +233 538 999 972
                        </li>
                        
                    </ul>

                    <h3 className='mb-2 mt-3 font-[500] text-[22px]'>Location</h3>
                    <ul className='flex flex-col gap-2'>
                        <li>
                            AK-359-7809
                        </li>
                        <li>
                        Panpan Street, Agric Nzema
                        </li>
                        <li>
                        Kumasi-Ghana
                        </li>
                        
                    </ul>

                </div>
            </div>

            <div className='flex gap-20 items-end'>
                <p>
                    &copy; Epahubb2024
                </p>
                <div>
                    <h3 className='font-[500] text-[22px]'>Follow</h3>
                    <div className='flex gap-2'>
                        <a href='https://web.facebook.com/profile.php?id=100086835577380' className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center">
                            <img src={facebook} alt="facebook" />
                        </a>
                        <a href='https://twitter.com/epahubb' className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center">
                            <img src={twitter} alt="facebook" />
                        </a>
                        <a href='' className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center">
                            <img src={linkedIn} alt="facebook" />
                        </a>
                        <a href='https://www.instagram.com/epahubbconsultghana/' className="h-[33px] w-[33px] border border-white rounded-full flex justify-center items-center">
                            <img src={instagram} alt="facebook" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer