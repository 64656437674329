import React, { useState } from 'react'
import employeeImg from "../assets/hrmSystem/employee.png"
import payroll from "../assets/hrmSystem/payroll.png"
import accountant from "../assets/hrmSystem/accountant.png"
import administrator from "../assets/hrmSystem/administrator.png"

const HrmSystem = () => {
    const [activeTab, setActiveTab] = useState(1)
  return (
    <div id='about' className='bg-[#DCE2C7] rounded-[7px] py-8 mx-4'>
        <div className="container">
            <h2 className='heading-2 text-center lg:text-[50px] text-[30px] 8xl:w-2/5 mx-auto leading-tight'>
                The <span className='text-[#6FD943]'>Complete</span> HRM System Your Business Needs...
            </h2>

            <div className='mt-11 grid gap-32 lg:gap-12 grid-cols-4 overflow-auto'>
                <p onClick={()=>setActiveTab(1)} className={`pb-1 lg:px-7 px-3 border-b border-black w-max ${activeTab === 1 && "text-[#50CD89]"} cursor-pointer`}>
                    Employee
                </p>
                <p onClick={()=>setActiveTab(2)} className={`pb-1 lg:px-7 px-3 border-b border-black w-max ${activeTab === 2 && "text-[#50CD89]"} cursor-pointer`}>
                    Payroll
                </p>
                <p onClick={()=>setActiveTab(3)} className={`pb-1 lg:px-7 px-3 border-b border-black w-max ${activeTab === 3 && "text-[#50CD89]"} cursor-pointer`}>
                    Accountant
                </p>
                <p onClick={()=>setActiveTab(4)} className={`pb-1 lg:px-7 px-3 border-b border-black w-max ${activeTab === 4 && "text-[#50CD89]"} cursor-pointer`}>
                    Administrator
                </p>
            </div>

            {
                activeTab === 1 && (
                    <div className='rounded-[20px] grid lg:grid-cols-2 mt-10'>
                        <div className='bg-white text-black p-10 lg:rounded-tl-2xl lg:rounded-bl-2xl order-2 lg:order-1'>
                            <h3 className=' 8xl:w-[50%] leading-tight mb-4 lg:text-[40px] text-[30px] text-center font-[400] heading-2'>
                                Manage Employee Easily
                            </h3>
                            <p className=' 8xl:w-[65%] text-gray-400 lg:mb-3 lg:mt-5 mb-10 text-center'>
                                Create, update, and manage employee profiles, track contract status, and handle transfers or terminations efficiently.
                            </p>
                            <ul>
                                <li>Comprehensive Employee Database.</li>
                                <li>Document Management</li>
                                <li>Application Tracking System</li>
                                <li>Onboarding and Recruitment</li>
                            </ul>
                        </div>
                        <div className='order-1 lg:order-1'>
                            <img src={employeeImg} alt="" className='w-full' />
                        </div>
                    </div>
                )
            }

            {
                activeTab === 2 && (
                    <div className='rounded-[20px] grid lg:grid-cols-2 mt-10'>
                        <div className='bg-white text-black p-10 lg:rounded-tl-2xl lg:rounded-bl-2xl order-2 lg:order-1'>
                            <h3 className=' 8xl:w-[50%] leading-tight mb-4 lg:text-[40px] text-[30px] text-center font-[400] heading-2'>
                                Payroll and Benefit Administration
                            </h3>
                            <p className=' 8xl:w-[65%] text-gray-400 lg:mb-3 lg:mt-5 mb-10 text-center'>
                                Calculate Salaries, schedule deposits and make sure all your employees are paid on time.
                            </p>
                            <ul>
                                <li>Track employee attendance.</li>
                                <li>Document Management</li>
                                <li>Employee overtime feature</li>
                                <li>Automated biometric fingerprint attendance system.</li>
                            </ul>
                        </div>
                        <div className='order-1 lg:order-1'>
                            <img src={payroll} alt="" className='w-full' />
                        </div>
                    </div>
                )
            }

            {
                activeTab === 3 && (
                    <div className='rounded-[20px] grid lg:grid-cols-2 mt-10'>
                        <div className='bg-white text-black p-10 lg:rounded-tl-2xl lg:rounded-bl-2xl order-2 lg:order-1'>
                            <h3 className=' 8xl:w-[50%] leading-tight mb-4 lg:text-[40px] text-[30px] text-center font-[400] heading-2'>
                                Accountant
                            </h3>
                            <p className=' 8xl:w-[65%] text-gray-400 lg:mb-3 lg:mt-5 mb-10 text-center'>
                                The accountant portal within Talentforge offers several functions tailored to their specific needs and responsibilities
                            </p>
                            <ul>
                                <li>Payroll Management</li>
                                <li>Expense Management</li>
                                <li>Financial Reporting</li>
                                <li>Budgeting and Forcasting</li>
                            </ul>
                        </div>
                        <div className='order-1 lg:order-1'>
                            <img src={accountant} alt="" className='w-full' />
                        </div>
                    </div>
                )
            }

            {
                activeTab === 4 && (
                    <div className='rounded-[20px] grid lg:grid-cols-2 mt-10'>
                        <div className='bg-white text-black p-10 lg:rounded-tl-2xl lg:rounded-bl-2xl order-2 lg:order-1'>
                            <h3 className=' 8xl:w-[50%] leading-tight mb-4 lg:text-[40px] text-[30px] text-center font-[400] heading-2'>
                                Administrator
                            </h3>
                            <p className=' 8xl:w-[65%] text-gray-400 lg:mb-3 lg:mt-5 mb-10 text-center'>
                                The administrator’s portal have access to all user dashboard. Makes it easier to access and regulate employees activities
                            </p>
                            <ul>
                                <li>Data Management</li>
                                <li>Employee Communication</li>
                                <li>User Management</li>
                                
                            </ul>
                        </div>
                        <div className='order-1 lg:order-1'>
                            <img src={administrator} alt="" className='w-full' />
                        </div>
                    </div>
                )
            }


            <div className="flex justify-center items-center">
                <div className="py-3 px-7 border border-black rounded-full lg:w-[50%] mx-auto text-center text-[14px] mt-7">
                    We’re all going through. <b>TeapHRM</b> really helped me deal with my anxiety and pressure to please other people
                </div>
            </div>

        </div>
    </div>
  )
}

export default HrmSystem